export const SERVICIOS = {
  ECONOMICOS: 'ECONOMICOS',
  SALUD: 'SALUD',
  SOCIALES: 'SOCIALES',
};

export const LISTA_SERVICIOS = {
  TURNOS: 'TURNOS',
  PRESTAMOS: 'PRESTAMOS',
  CAJA_AHORROS: 'CAJA_AHORROS',
  AHORROS: 'AHORROS',
  TURNOS_TRABAJO_SOCIAL: 'TURNOS_TRABAJO_SOCIAL',
  CURSOS_TALLERES: 'CURSOS_TALLERES',
  BIBLIOTECA: 'BIBLIOTECA',
};

export const URL_ICONS = {
  ECONOMICOS: {
    url: '/assets/img/v2/servicios/ico_econo.svg',
    title: 'Económicos',
    title_vista_detalle: 'Mis ayudas',
    history_route: '/app/grupos-servicios/grilla-servicios',
  },
  SALUD: {
    url: '/assets/img/v2/servicios/ico_salud.svg',
    title: 'Salud',
    title_vista_detalle: 'Mis turnos',
    history_route: '/app/grupos-servicios/grilla-servicios',
  },
  SOCIALES: {
    url: '/assets/img/v2/servicios/ico_sociales.svg',
    title: 'Sociales',
    title_vista_detalle: 'Mis turnos Trabajo Social',
    history_route: '/app/grupos-servicios/grilla-servicios',
  },
  TURNOS: {
    url: '/assets/img/v2/grupo_servicios/salud/ico_turno_salud_azul.svg',
    title: 'Turnos',
    title_vista_detalle: 'Mis Turnos',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/salud/turnos',
  },
  PRESTAMOS: {
    url: '/assets/img/v2/grupo_servicios/economicos/ico_pesos_econo_verde.svg',
    title: 'Prestamos',
    title_vista_detalle: 'Mis préstamos',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/economicos/prestamos',
  },
  CAJA_AHORROS: {
    url: '/assets/img/v2/grupo_servicios/economicos/ico_ahorro_econo_verde.svg',
    title: 'Caja ahorros',
    title_vista_detalle: 'Mi caja de ahorro',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/economicos/caja-ahorros',
  },
  AHORROS: {
    url: '/assets/img/v2/grupo_servicios/economicos/ico_econo.svg',
    title: 'Ahorros',
    title_vista_detalle: 'Mis Ahorros',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/economicos/ahorros',
  },
  TURNOS_TRABAJO_SOCIAL: {
    url: '/assets/img/v2/grupo_servicios/sociales/ico_turno_sociales_rojo.svg',
    title: 'Turnos Trabajo Social',
    title_vista_detalle: 'Mis Turnos trabajo social',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/sociales/turnos-serv-social',
  },
  CURSOS_TALLERES: {
    url: '/assets/img/v2/grupo_servicios/sociales/ico_curso_sociales_rojo.svg',
    title: 'Cursos y talleres',
    title_vista_detalle: 'Mis cursos',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/sociales/cursos-talleres',
  },
  BIBLIOTECA: {
    url: '/assets/img/v2/grupo_servicios/sociales/ico_curso_biblio_rojo.svg',
    title: 'Biblioteca',
    title_vista_detalle: 'Biblioteca',
    estiloImg: 'border-custom',
    estiloSpan: 'label-servicio',
    history_route: '/app/grupos-servicios/sociales/pedidos-biblioteca',
  },
};
