import React from 'react';
import { useDispatch } from 'react-redux';
import { URL_ICONS } from './constants';
import { useHistory } from 'react-router-dom';
import { setCircuito } from '../../../redux/root-actions';

export const ServiceButton = ({ CIRCUIT }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    url,
    title,
    estiloImg = 'mb-2 bg-white p-3 border-radius-20',
    estiloSpan = 'white',
    history_route,
  } = URL_ICONS[CIRCUIT];

  const handleServiceButton = () => {
    dispatch(setCircuito(CIRCUIT));
    history.push(history_route);
  };
  return (
    <div
      className="d-flex flex-column align-items-center flex-wrap pr-1 c-pointer"
      style={{ minWidth: '100px' }}
      onClick={handleServiceButton}
    >
      <img alt={title} src={url} className={estiloImg} />
      <span className={estiloSpan}>{title}</span>
    </div>
  );
};
