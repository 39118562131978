import {
  FETCH_NOTIF_REQUEST,
  FETCH_NOTIF_SUCCESS,
  FETCH_NOTIF_ERROR,
  SET_NOTIF_LEIDA_REQUEST,
  SET_NOTIF_LEIDA_SUCCESS,
  DELETE_NOTIF_REQUEST,
  DELETE_NOTIF_SUCCESS,
} from '../actions';

const parseNotif = (notif) => {
  const lstModal = notif.data.data.filter((s) => s.DISPLAY === 'MODAL');
  const lstSlider = notif.data.data.filter((s) => s.DISPLAY === 'SLIDER');
  const lstHeader = notif.data.data.filter((s) => s.DISPLAY === 'HEADER');
  return { modal: lstModal, slider: lstSlider, header: lstHeader };
};

export const fetchNotifRequest = (codMutual, cuil) => {
  return {
    type: FETCH_NOTIF_REQUEST,
    payload: { codMutual, cuil },
  };
};
export const fecthNotifSuccess = (notificaciones) => ({
  type: FETCH_NOTIF_SUCCESS,
  payload: parseNotif(notificaciones),
});
export const fectchNotifError = (message) => ({
  type: FETCH_NOTIF_ERROR,
  payload: { message },
});
export const deleteNotif = (notifId, codMutual, cuil) => ({
  type: DELETE_NOTIF_REQUEST,
  payload: { notifId, codMutual, cuil },
});
export const deleteNotifSuccess = (notifHeader) => ({
  type: DELETE_NOTIF_SUCCESS,
  payload: notifHeader.data,
});
export const setNotifLeidas = (notifId, codMutual, cuil) => ({
  type: SET_NOTIF_LEIDA_REQUEST,
  payload: { notifId, codMutual, cuil },
});
export const setNotifLeidaSuccess = (notifHeader) => ({
  type: SET_NOTIF_LEIDA_SUCCESS,
  payload: notifHeader.data,
});
