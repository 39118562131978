import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { createRoot } from 'react-dom/client';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));
createRoot(document.getElementById('root')).render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>
);
