import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools()
      ? compose(applyMiddleware(...middlewares), composeWithDevTools())
      : compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  //   if (module.hot) {
  //     module.hot.accept('./reducers', () => {
  //       // eslint-disable-next-line @typescript-eslint/no-var-requires
  //       const nextRootReducer = require('./reducers');
  //       store.replaceReducer(nextRootReducer);
  //     });
  //   }

  return store;
}
export default configureStore;
