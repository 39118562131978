import axios from 'axios';
import { authHeader } from 'siam-utils';
import { appConfig } from '../constants/defaultValues';

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${appConfig.apiUrl}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${user.user.token}`;
      return user;
    });
}

function loginWithCuil(cuil, username, password) {
  const token = localStorage.getItem('token');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ cuil, username, password }),
  };

  return fetch(`${appConfig.apiUrl}/auth/login_with_cuil`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${user.user.token}`;
      return user;
    });
}

function getByToken(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios.get('/api/current').then((user) => {
    return user.data;
  });
}

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('expire');
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${appConfig.apiUrl}/users`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${appConfig.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${appConfig.apiUrl}/auth/register`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${appConfig.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${appConfig.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.json().then((data) => {
    if (!response.ok) {
      let error = (data && data.message) || response.statusText;
      switch (response.status) {
        case 400:
          error =
            'El DNI o clave son incorrectos. No pertenecen a un asociado registrado.';
          break;
        case 401:
          error = data.error;
          logout();
          break;
        default:
          break;
      }
      return Promise.reject({ message: error });
    }
    return data;
  });
}

function changeFirstTime() {
  const token = localStorage.getItem('token');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  };
  return fetch(`${appConfig.apiUrl}/api/user/mark-first-time`, requestOptions);
}

export const authService = {
  login,
  loginWithCuil,
  getByToken,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  changeFirstTime,
};
export default authService;
