import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import './assets/css/react-quill/quill.bubble.css';
import axios from 'axios';
import setIdioma from './env/idioma';
import * as serviceWorker from './serviceWorker';
import {
  defaultColor,
  themeColorStorageKey,
  appConfig,
} from './constants/defaultValues';

const color = localStorage.getItem(themeColorStorageKey)
  ? localStorage.getItem(themeColorStorageKey)
  : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const { apiUrl } = appConfig;

axios.defaults.baseURL = apiUrl;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const error = console.error;
console.error = (...args) => {
  if (
    /defaultProps/.test(args[0]) ||
    /findDOMNode/.test(args[0]) ||
    /childContextTypes/.test(args[0]) ||
    /Variable dynamic imports/.test(args[0])
  )
    return;
  error(...args);
};

const render = () => {
  setIdioma();
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
  setIdioma();
};
render();
setIdioma();
serviceWorker.register();
