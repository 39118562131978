import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authService } from '../../services';
import { LOGIN_USER, CONNECT_USER, LOGOUT_USER, CHANGE_USER } from '../actions';
import { fetchNotifRequest } from '../notificaciones/actions';
import {
  loginUserSuccess,
  loginUserError,
  connectUserSuccess,
  connectUserError,
} from './actions';

const loginWithEmailPasswordAsync = async (email, password) =>
  await authService
    .login(email, password)
    .then((authUser) => {
      return authUser;
    })
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      localStorage.setItem('token', loginUser.user.token);
      localStorage.setItem('expire', Date.now() + 3600000); // 1 hs.. = 3600000
      yield put(loginUserSuccess(loginUser));
      history.push('/app/main/start-collapse-all');
      setTimeout(() => {
        history.go(0);
      }, 200);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const getCurrentUSerAsync = async (token) =>
  await authService
    .getByToken(token)
    .then((authUser) => authUser)
    .catch((error) => error);

function* getCurrenUser({ payload }) {
  const { token } = payload;
  try {
    const actualUser = yield call(getCurrentUSerAsync, token);
    if (!actualUser.message) {
      const { COD_MUTUAL, CUIL } = actualUser.socio[0];
      localStorage.setItem('expire', Date.now() + 3600000); // 1 hs.. = 3600000
      yield put(connectUserSuccess(actualUser));
      yield put(fetchNotifRequest(COD_MUTUAL, CUIL));
    } else {
      localStorage.removeItem('expire');
      localStorage.removeItem('token');
      window.location.replace(process.env.PUBLIC_URL);
      yield put(connectUserError(actualUser.message));
    }
  } catch (error) {
    localStorage.removeItem('expire');
    localStorage.removeItem('token');
    window.location.replace(process.env.PUBLIC_URL);
    yield put(connectUserError(error.message));
  }
}

export function* watchConnectUser() {
  yield takeEvery(CONNECT_USER, getCurrenUser);
}

function* getChangeUSer({ payload }) {
  const { token } = payload;

  try {
    const actualUser = yield call(getCurrentUSerAsync, token);
    if (!actualUser.message) {
      yield put(connectUserSuccess(actualUser));
    } else {
      yield put(connectUserError(actualUser.message));
    }
  } catch (error) {
    yield put(connectUserError(error.message));
  }
}

export function* watchChangeUSer() {
  yield takeEvery(CHANGE_USER, getChangeUSer);
}

// eslint-disable-next-line require-yield
function* logout({ payload }) {
  const { history } = payload;
  localStorage.removeItem('expire');
  localStorage.removeItem('token');
  history.push('/app/user/login');
  history.go(0);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchConnectUser),
    fork(watchLogoutUser),
    fork(watchChangeUSer),
  ]);
}
